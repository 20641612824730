.ctp-traffic {
  min-height: 300px;
  max-height: 650px;

  .disruption {

    &__network-container {
      width: 100%;
      margin-bottom: 10px;
      border-radius: 6px;

      .disruption__network-title {
        color: #FFFFFF;
      }

      &.disrupt {
        background-color: #d3161b;
      }

      &.warning {
        background-color: #ff9000;
      }

      &.information {
        background-color: #64a575;
      }
    }

    &__network-title {
      display: flex;
      position: relative;
      font-size: 14px;
      line-height: 28px;
      padding: 0 7px;

      i {
        align-self: center;
        margin-right: 5px;
        font-size: 13px;
        cursor: pointer;
      }

      span {
        font-weight: 600;
        margin-right: 5px;
      }
    }

    &__network-title-resume {
      width: 75%;
      line-height: 15px;
      margin: 10px 0;
    }

    &__network-show-less {
      display: flex;
      position: absolute;
      right: 7px;
      font-size: 10px;
      cursor: pointer;

      i {
        font-size: 7px;
        margin-left: 5px;
        align-self: center;
      }

      span {
        text-decoration: underline;
      }
    }

    &__network-content {
      font-size: 14px;
      margin: 0 5px 5px 5px;
      background-color: #FFFFFF;
      border-radius: 6px;
      padding: 7px;
    }

    &__lines-header {
      display: flex;
      background-color: #f1f1f1;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }

    &__lines-mode {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      flex-direction: column;
      padding: 0 10px;
      width: 7vw;

      &.selected {
        background-color: #FFFFFF;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        &:before, &:after {
          content: " ";
          height: 100%;
          position: absolute;
          top: 0;
          width: 15px;
        }
        &:before {
          box-shadow: -9px 0 11px -15px inset;
          left: -15px;
        }
        &:after {
          box-shadow: 9px 0 11px -15px inset;
          right: -15px;
        }
      }

      &:first-child {
        &.selected {
          &:before {
            box-shadow: none;
          }
        }
      }
    }

    &__lines-mode-length {
      font-size: 14px;
      height: 20px;
      width: 20px;
      border-radius: 7px;
      line-height: 25px;
      text-align: center;

      i.information {
        color: #64a575;
      }
      i.warning {
        color: #ff9000;
      }
      i.disrupt {
        color: #d3161b;
      }
    }

    &__lines-mode-header {
      text-align: center;
      display: flex;
      align-items: center;

      img {
        margin-right: 4px;
      }
    }

    &__lines-mode-content {
      display: none;

      &.selected {
        display: flex;
        min-height: 90px;
      }

      .disruption__lines-row {
        width: 100%;
        padding-left: 0;

        .disruption__lines-item {
          position: relative;
          list-style-type: none;
          width: 75px;
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 3%;
          margin: 15px 0px;
          cursor: pointer;
          float: left;
          
          span {
            margin-left: 3px;
          }

          i {
            font-size: 15px;
            background-color: #FFFFFF;
            border-radius: 22px;
            position: absolute;
            right: 5px;
            top: 10px;

            &.information {
              color: #64a575;
            }
            &.warning {
              color: #ff9000;
            }
            &.disrupt {
              color: #d3161b;
            }
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    &__lines {
      width: 90%;
      margin: 0 auto;
      box-shadow: 0 0 9px #cdcdcd;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    &__line-contrast {
      font-weight: bold;
      line-height: 2;
      float: left;
      height: 24px;
      display: block;
      min-width: 24px;
      text-align: center;
      border-radius: 3px;
      font-family: Arial, sans-serif;
      padding: 0 3px;
      font-size: 12px;
    }

    &__info {
      display: flex;
      width: 90%;
      flex-direction: column;
      margin: 0 auto;
      font-size: 12px;
    }
    
    &__info-subtitle {
      color: $color-main-one;
      border-bottom: 1px solid $color-main-one;
    }

    &__info-title {
      margin: 20px 0px;
    }

    &__lines-item-container {
      display: flex;
      flex-direction: column;
      padding: 5px;
      width: 100%;
    }

    &__lines-container-resume {
      display: flex;
      width: 100%;
      margin: 20px 0;
      justify-content: left;
      align-items: left;

      span {
        margin-right: 8px;
      }

      i.information {
        color: #64a575;
      }
      i.warning {
        color: #ff9000;
      }
      i.disrupt {
        color: #d3161b;
      }
    }

    &__lines-container-body {
      background-color: #f1f1f1;
      border-radius: 5px;
      padding: 10px;
    }

    &__lines-container-back {
      font-size: 11px;
      color: $color-main-one;
      text-decoration: underline;
      margin: 10px 0 0 8px;
      cursor: pointer;
      width: 50px;
    }

    &__lines-container-resume-container {
      position: relative;

      i {
        position: absolute;
        top: -10px;
        right: 29px;
        background-color: #FFFFFF;
        border-radius: 15px;
      }
    }

    &__line-header {
      display: flex;
      line-height: 14px;
      margin: 13px 0;
      font-size: 14px;
      border-bottom: solid 3px #251942;
      height: 54px;

      .last-update {
        align-items: left;
        justify-content: left;
        font-weight: 600;
        padding: 20px 6px;
        font-size: 10px;
        border: none;
        
        span {
          margin-left: 0px;
        }
        i {
          font-size: 18px;
          margin-right: 3px;
        }
        width: 33.33%;
      }
      div {
        display: flex;
        align-items: center;
        padding: 20px;
        font-size: 20px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border: solid 1px #251942;
        border-bottom: none;
        cursor: pointer;
        width: 33.33%;
        justify-content: center;
        text-align: center;

        span {
          margin-left: 10px;
        }
      }

      span {
        cursor: pointer;
      }

      .disruption__count {
        text-decoration: none;
      }

      .selected {
        background-color: #251942;
        color: #FFFFFF;
      }
    }

    &__count {
      font-size: 10px;
      background-color: #d3161b;
      height: 15px;
      width: 15px;
      border-radius: 14px;
      color: #FFFFFF;
      text-align: center;
      margin-left: 4px;
    }

    &__line-body-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .disruption__line-show-less {
        font-size: 11px;
        text-decoration: underline;
        color: #507E78;
        cursor: pointer;

        i {
          font-size: 8px;
        }
      }
    }

    &__line-body-content {
      margin-top: 10px;
      font-size: 11px;
    }

    &__line-body {
      margin-top: 35px;
    }

    &__no-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;

      &.size-md {
        height: 100px;
      }
    }
  }

  .hide {
    display: none !important;
  }
}

#ctp-traffic__content {
  display: flex;
  margin: 0 auto;
  width: 90%;
  padding-top: 20px;
  flex-direction: column;
}
